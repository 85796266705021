@keyframes calendar-pulse-animation {
  0% {
    filter: brightness(1); /* Normal brightness */
  }
  50% {
    filter: brightness(0.75); /* Darker */
  }
  100% {
    filter: brightness(1); /* Back to normal brightness */
  }
}

.calendar-blinking-event {
  animation: calendar-pulse-animation 2s;
  animation-iteration-count: 3;
}
