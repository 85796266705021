table tr td {
  .deleted-row-visible-content {
    display: none;
  }
}
table tr.deleted-row {
  position: relative;
  pointer-events: none;

  td {
    position: relative;
  }
  td::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    pointer-events: all;
    z-index: 1;
  }

  td::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid #f53939;
    z-index: 2;
  }

  td:has(.deleted-row-visible-content) {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
    > * {
      display: none;
    }
    > .deleted-row-visible-content {
      display: block;
      pointer-events: all;
    }
    > .deleted-row-visible-content.flex {
      display: flex;
    }
  }
}
